<template>
  <div class="app-container equityheader summary-page">
    <eHeader ref="header" :query="query" />
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      size="small"
      stripe
      style="width: 100%;"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="siteName" label="社区名称"/>
      <el-table-column prop="subscriptionId" label="订阅编号"/>
      <el-table-column prop="userName" label="订阅者">
        <template slot-scope="scope">
          <div>{{scope.row.copyUserName}}</div>
          <div>{{scope.row.copyLogin}}</div>
        </template>
      </el-table-column>
      <el-table-column label="信号源">
        <template slot-scope="scope">
          <template v-if="scope.row.signalUserName">
            <div>{{scope.row.signalUserName}}</div>
            <div>{{scope.row.signalLogin}}</div>
          </template>
          <template v-else>-</template>
        </template>
      </el-table-column>
      <el-table-column prop="ownerUserName" label="收益 (USDT)">
        <template slot-scope="scope">
          <span class="earing" :class="{active: getActive(scope.row)}">{{scope.row.profit}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="表现费(USDT)">
        <template slot-scope="scope">
          <div>{{scope.row.amount}}</div>
          <div>*{{scope.row.proportion * 100}}%</div>
        </template>
      </el-table-column>
      <el-table-column prop="siteAmount" label="站长所得"/>
      <el-table-column prop="signalAmount" label="信号所得"/>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="结算时间">
        <template slot-scope="scope">
          <span>{{$parseTime(scope.row.createTime)}}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import initData from '@/mixins/initData'
  import eHeader from '@/components/community/subscription/settleLog/header'
  export default {
    components: {
      eHeader,
    },
    mixins: [initData],
    data() {
      return {
        statistics: {
          profit: '',
          amount: '',
          siteAmount: '',
          signalAmount: '',
        },
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.init();
      });
    },
    beforeDestroy(){
      this.$store.commit('setSubflowNumber', '');
    },
    computed: {
      ...mapGetters(['subflowNumber']),
    },
    methods: {
      beforeInit() {
        this.url = '/community/crm/settlementRecord'
        this.params = {
          page: this.page,
          size: this.size,
          status: this.query.status,
          id: this.query.id,
          copyInfo: this.query.copyInfo,
          signalInfo: this.query.signalInfo,
        }
        if(this.subflowNumber){
          this.$refs.header.subscriptionId = this.subflowNumber;
          this.$store.commit('setSubflowNumber','');
        }
        if(this.$refs.header.subscriptionId){
          this.params.transId = this.$refs.header.subscriptionId;
        }
        if (this.$refs.header.$refs.searchcommunity.siteId) {
          this.params['siteId'] = this.$refs.header.$refs.searchcommunity.siteId
        }
        if(this.query.startTime){
          this.params.startTime = this.$parseTime(this.query.startTime);
        }
        if(this.query.endTime){
          this.params.endTime = this.$parseTime(this.query.endTime);
        }
        return true;
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          switch (index) {
            case 0:
              sums[index] = '合计';
              break;
            case 5:
              sums[index] = `${this.statistics.profit}`;
              break;
            case 6:
              sums[index] = `${this.statistics.amount}`;
              break;
            case 7:
              sums[index] = `${this.statistics.siteAmount}`;
              break;
            case 8:
              sums[index] = `${this.statistics.signalAmount}`;
              break;
          }
        });
        return sums;
      },
      getActive(row){
        return row.profit < 0;
      },
      getStatus(row){
        switch (row.status) {
          case 1:
            return '待支付';
          case 2:
            return '已完成';
        }
      }
    }
  }
</script>

<style lang="less" scoped>
.equityheader {
  .earing {
    &.active {
      color: red;
    }
  }
}
</style>
